import Auth from '../../utils/Auth';
import { axiosInstance } from "./index";
const handlerEnabled = true;

const registerUser = async (params) => {
    return await axiosInstance.post('/api/register', params, {
        handlerEnabled
    });
};

const login = async (email, password, userType) => {
    return await axiosInstance.post('api/login', {
        email,
        password,
        type: userType
    }, {
        handlerEnabled
    });
};

const getUserInfo = async () => {
    return await axiosInstance.get('api/user', {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
}

const getSubsInfo = async (phone) => {
    return await axiosInstance.get(`api/external/getsubscriber?msisdn=${phone}`, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
}

const getUserAccounts = async () => {
    return await axiosInstance.get('api/useraccounts', {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
}

const resetPasswordRequest = async (params) => {
    return await axiosInstance.post('/api/resetpasswordrequest', params, {
        handlerEnabled
    });
};

export default {
    registerUser,
    login,
    getUserInfo,
    getSubsInfo,
    getUserAccounts,
    resetPasswordRequest
};