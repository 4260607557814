import { call, put } from "redux-saga/effects";
import API from "../../network/apis/Specialty";
import { dispatchSnackbarError } from "../../utils/Shared";
import * as ACTIONS from "../actions/Specialty";
import * as TYPES from "../types/SpecialtyTypes";
import { takeLatest } from "redux-saga/effects";

export function* getSpecialties() {
    try {
        yield put(ACTIONS.enableGetSpecialtiesLoader());

        const response = yield call(API.getSpecialties);

        if (response.data.success) {
            yield put(ACTIONS.onGetSpecialtiesSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    } finally {
        yield put(ACTIONS.disableGetSpecialtiesLoader());
    }
}

export function* specialtySagas() {
    yield takeLatest(TYPES.GET_SPECIALTIES_REQUEST, getSpecialties);
}