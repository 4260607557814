import Auth from '../../utils/Auth';
import { axiosInstance } from "./index";
const handlerEnabled = true;

const getAppointments = async (userType, userId, tab) => {
    return await axiosInstance.get(`api/users/${userId}/${userType}_${tab}_appointments`, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
}

const getMoreAppointments = async (url) => {
    return await axiosInstance.get(url, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
}

const addAppointmentImage = async (appointmentId, userType, base64Image) => {
    return await axiosInstance.post(`api/appointments/${appointmentId}/${userType}image`, {
        image: base64Image
    }, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
};

const updateAppointmentImage = async (appointmentId, userType, images) => {
    return await axiosInstance.put(`api/appointments/${appointmentId}/${userType}image`, {
        images: images.length > 0 ? images : ''
    }, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
};

const getAppointment = async (appointmentId) => {
    return await axiosInstance.get(`api/appointments/${appointmentId}`, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
}

const updatePrescription = async (appointmentId, params) => {
    return await axiosInstance.put(`api/appointments/${appointmentId}/edit_prescription`,
        params,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

const getAppointmentToken = async (appointmentId) => {
    return await axiosInstance.get(`api/appointments/${appointmentId}/token`, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
}

const getCalendarAppointments = async (userType, userId) => {
    return await axiosInstance.get(`api/appointments/${userType}/${userId}/calendar`, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
}

const getCalendarSlots = async (userId, day) => {
    return await axiosInstance.get(`api/doctors/${userId}/calendar/slots?date=${day}`, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
}

const updateCalendarSlots = async (params) => {
    return await axiosInstance.put(`api/doctors/occupiedslot/update`,
        params,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

const bookAppointmentViaCredit = async (params) => {
    return await axiosInstance.post(`api/appointments/book_via_credit`,
        params,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

export default {
    getAppointments,
    getMoreAppointments,
    addAppointmentImage,
    getAppointment,
    updateAppointmentImage,
    updatePrescription,
    getAppointmentToken,
    getCalendarAppointments,
    getCalendarSlots,
    updateCalendarSlots,
    bookAppointmentViaCredit
};