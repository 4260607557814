import { call, put } from "redux-saga/effects";
import API from "../../network/apis/Doctor";
import { dispatchSnackbarSuccess, dispatchSnackbarError } from "../../utils/Shared";
import * as ACTIONS from "../actions/Doctor";
import * as TYPES from "../types/DoctorTypes";
import { takeLatest } from "redux-saga/effects";

export function* getFeaturedDoctors() {
    try {
        const response = yield call(API.getFeaturedDoctors);

        if (response.data.success) {
            yield put(ACTIONS.onGetFeaturedDoctorsSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getDoctorSchedule(action) {
    try {
        const response = yield call(API.getDoctorSchedule, action.doctorUserId, action.date);

        if (response.data.success) {
            yield put(ACTIONS.onGetDoctorScheduleSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getAllSpecialties() {
    try {
        const response = yield call(API.getAllSpecialties);

        if (response.data.success) {
            yield put(ACTIONS.onGetAllSpecialtiesSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getDoctorsInfo(action) {
    try {
        const response = yield call(API.getDoctorsInfo, action.userId);

        if (response.data.success) {
            yield put(ACTIONS.onGetDoctorsInfoSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* updateDoctorsInfo(action) {
    try {
        const response = yield call(API.updateDoctorsInfo, action.userId, action.params);

        if (response.data.success) {
            yield put(ACTIONS.onUpdateDoctorsInfoSuccess());
            dispatchSnackbarSuccess(response.data.message);
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getSchedule(action) {
    try {
        const response = yield call(API.getSchedule, action.userId);

        if (response.data.success) {
            yield put(ACTIONS.onGetScheduleSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* updateSchedule(action) {
    try {
        const response = yield call(API.updateSchedule, action.userId, action.params);

        if (response.data.success) {
            yield put(ACTIONS.onUpdateScheduleSuccess());
            dispatchSnackbarSuccess(response.data.message);
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getDoctorsBySpecialty(action) {
    try {
        const response = yield call(API.getDoctorsBySpecialty, action.specialtyId);

        if (response.data.success) {
            yield put(ACTIONS.onGetDoctorsBySpecialtySuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getMoreDoctorsBySpecialty(action) {
    try {
        const response = yield call(API.getMoreDoctorsBySpecialty, action.url);

        if (response.data.success) {
            yield put(ACTIONS.onGetMoreDoctorsBySpecialtySuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getDoctorEarnings(action) {
    try {
        const response = yield call(API.getDoctorEarnings, action.userId, action.params);

        if (response.data.success) {
            yield put(ACTIONS.onGetDoctorEarningsSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getDoctorCredits(action) {
    try {
        const response = yield call(API.getDoctorCredits, action.doctorUserId);

        if (response.data.success) {
            yield put(ACTIONS.onGetDoctorCreditsSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* doctorSagas() {
    yield takeLatest(TYPES.GET_FEATURED_DOCTOR_REQUEST, getFeaturedDoctors);
    yield takeLatest(TYPES.GET_DOCTOR_SCHEDULE_REQUEST, getDoctorSchedule);
    yield takeLatest(TYPES.GET_ALL_SPECIALTIES, getAllSpecialties);
    yield takeLatest(TYPES.GET_DOCTORS_INFO, getDoctorsInfo);
    yield takeLatest(TYPES.UPDATE_DOCTORS_INFO, updateDoctorsInfo);
    yield takeLatest(TYPES.GET_SCHEDULE, getSchedule);
    yield takeLatest(TYPES.UPDATE_SCHEDULE, updateSchedule);
    yield takeLatest(TYPES.GET_DOCTORS_BY_SPECIALTY, getDoctorsBySpecialty);
    yield takeLatest(TYPES.GET_MORE_DOCTORS_BY_SPECIALTY, getMoreDoctorsBySpecialty);
    yield takeLatest(TYPES.GET_DOCTOR_EARNINGS, getDoctorEarnings);
    yield takeLatest(TYPES.GET_DOCTOR_CREDITS, getDoctorCredits);
}