export const AUTH_REGISTER_REQUEST = 'AUTH_REGISTER_REQUEST';
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';

export const AUTH_USER_INFO_REQUEST = 'AUTH_USER_INFO_REQUEST';
export const AUTH_USER_INFO_SUCCESS = 'AUTH_USER_INFO_SUCCESS';

export const AUTH_SUBS_INFO_REQUEST = 'AUTH_SUBS_INFO_REQUEST';
export const AUTH_SUBS_INFO_SUCCESS = 'AUTH_SUBS_INFO_SUCCESS';

export const AUTH_USER_ACCOUNTS_REQUEST = 'AUTH_USER_ACCOUNTS_REQUEST';
export const AUTH_USER_ACCOUNTS_SUCCESS = 'AUTH_USER_ACCOUNTS_SUCCESS';

export const AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST';
