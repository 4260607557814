import * as types from '../types/DoctorTypes';

export const getFeaturedDoctors = () => ({
    type: types.GET_FEATURED_DOCTOR_REQUEST
});

export const onGetFeaturedDoctorsSuccess = (payload) => ({
    type: types.GET_FEATURED_DOCTOR_SUCCESS,
    payload
});

export const getDoctorSchedule = (doctorUserId, date) => ({
    type: types.GET_DOCTOR_SCHEDULE_REQUEST,
    doctorUserId,
    date
});

export const onGetDoctorScheduleSuccess = (payload) => ({
    type: types.GET_DOCTOR_SCHEDULE_SUCCESS,
    payload
});

export const getAllSpecialties = () => ({
    type: types.GET_ALL_SPECIALTIES
});

export const onGetAllSpecialtiesSuccess = (payload) => ({
    type: types.GET_ALL_SPECIALTIES_SUCCESS,
    payload
});

export const getDoctorsInfo = (userId) => ({
    type: types.GET_DOCTORS_INFO,
    userId
});

export const onGetDoctorsInfoSuccess = (payload) => ({
    type: types.GET_DOCTORS_INFO_SUCCESS,
    payload
});

export const updateDoctorsInfo = (userId, params) => ({
    type: types.UPDATE_DOCTORS_INFO,
    userId,
    params
});

export const onUpdateDoctorsInfoSuccess = (payload) => ({
    type: types.UPDATE_DOCTORS_INFO_SUCCESS,
    payload
});

export const getSchedule = (userId) => ({
    type: types.GET_SCHEDULE,
    userId
});

export const onGetScheduleSuccess = (payload) => ({
    type: types.GET_SCHEDULE_SUCCESS,
    payload
});

export const updateSchedule = (userId, params) => ({
    type: types.UPDATE_SCHEDULE,
    userId,
    params
});

export const onUpdateScheduleSuccess = (payload) => ({
    type: types.UPDATE_SCHEDULE_SUCCESS,
    payload
});

export const getDoctorsBySpecialty = (specialtyId) => ({
    type: types.GET_DOCTORS_BY_SPECIALTY,
    specialtyId
});

export const onGetDoctorsBySpecialtySuccess = (payload) => ({
    type: types.GET_DOCTORS_BY_SPECIALTY_SUCCESS,
    payload
});

export const getMoreDoctorsBySpecialty = (url) => ({
    type: types.GET_MORE_DOCTORS_BY_SPECIALTY,
    url
});

export const onGetMoreDoctorsBySpecialtySuccess = (payload) => ({
    type: types.GET_MORE_DOCTORS_BY_SPECIALTY_SUCCESS,
    payload
});

export const getDoctorEarnings = (userId, params) => ({
    type: types.GET_DOCTOR_EARNINGS,
    userId,
    params
});

export const onGetDoctorEarningsSuccess = (payload) => ({
    type: types.GET_DOCTOR_EARNINGS_SUCCESS,
    payload
});

export const getDoctorCredits = (doctorUserId) => ({
    type: types.GET_DOCTOR_CREDITS,
    doctorUserId
});

export const onGetDoctorCreditsSuccess = (payload) => ({
    type: types.GET_DOCTOR_CREDITS_SUCCESS,
    payload
});