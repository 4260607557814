import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import createSagaMiddleware from "redux-saga";
import { watchSagas } from "./sagas";
import { createLogger } from 'redux-logger';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['loader', 'snackbar', 'banner', 'promo', 'profile', 'faq', 'contact', 'appointment', 'doctor', 'account', 'specialty']
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middleware = [];
const saga = createSagaMiddleware();

middleware.push(saga);
// middleware.push(createLogger());

//redux dev tool
const composeEnhancers =
	typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
		: compose;
const enhancer = composeEnhancers(applyMiddleware(...middleware));

const store = createStore(persistedReducer, enhancer);

saga.run(watchSagas);

export default store;
