import * as types from "../types/SpecialtyTypes";

const INITIAL_STATE = {
    specialtiesList: [],
    isGetSpecialtiesLoading: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.ENABLE_GET_SPECIALTIES_LOADER:
            return {
                ...state,
                isGetSpecialtiesLoading: true
            };
        case types.DISABLE_GET_SPECIALTIES_LOADER:
            return {
                ...state,
                isGetSpecialtiesLoading: false
            }
        case types.GET_SPECIALTIES_REQUEST:
            return {
                ...state
            };
        case types.GET_SPECIALTIES_SUCCESS:
            return {
                ...state,
                specialtiesList: action.payload
            }
        default:
            return state;
    }
};