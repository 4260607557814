import * as types from '../types/AuthTypes';

export const requestRegister = (params) => ({
    type: types.AUTH_REGISTER_REQUEST,
    params
});

export const registerSuccess = (payload) => ({
    type: types.AUTH_REGISTER_SUCCESS,
    payload
});

export const requestLogin = (email, password, userType) => ({
    type: types.AUTH_LOGIN_REQUEST,
    email,
    password,
    userType
});

export const loginSuccess = (userInfo) => ({
    type: types.AUTH_LOGIN_SUCCESS,
    userInfo
});

export const logoutRequest = () => ({
    type: types.AUTH_LOGOUT_REQUEST
})

export const requestUserInfo = () => ({
    type: types.AUTH_USER_INFO_REQUEST
});

export const userInfoSuccess = (payload) => ({
    type: types.AUTH_USER_INFO_SUCCESS,
    payload
})

export const requestSubInfo = (phone) => ({
    type: types.AUTH_SUBS_INFO_REQUEST,
    phone
});

export const onRequestSubInfoSuccess = (payload) => ({
    type: types.AUTH_SUBS_INFO_SUCCESS,
    payload
});

export const requestUserAccounts = () => ({
    type: types.AUTH_USER_ACCOUNTS_REQUEST
});

export const onRequestUserAccountsSuccess = (payload) => ({
    type: types.AUTH_USER_ACCOUNTS_SUCCESS,
    payload
});

export const resetPasswordRequest = (params) => ({
    type: types.AUTH_RESET_PASSWORD_REQUEST,
    params
});