export const GET_APPOINTMENTS_REQUEST = 'GET_APPOINTMENTS_REQUEST';
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS';

export const GET_MORE_APPOINTMENTS_REQUEST = 'GET_MORE_APPOINTMENTS_REQUEST';
export const GET_MORE_APPOINTMENTS_SUCCESS = 'GET_MORE_APPOINTMENTS_SUCCESS';

export const ADD_APPOINTMENT_IMAGE = 'ADD_APPOINTMENT_IMAGE';
export const ADD_APPOINTMENT_IMAGE_SUCCESS = 'ADD_APPOINTMENT_IMAGE_SUCCESS';

export const UPDATE_APPOINTMENT_IMAGE = 'UPDATE_APPOINTMENT_IMAGE';
export const UPDATE_APPOINTMENT_IMAGE_SUCCESS = 'UPDATE_APPOINTMENT_IMAGE_SUCCESS';

export const GET_APPOINTMENT_REQUEST = 'GET_APPOINTMENT_REQUEST';
export const GET_APPOINTMENT_SUCCESS = 'GET_APPOINTMENT_SUCCESS';

export const UPDATE_PRESCRIPTION_REQUEST = 'UPDATE_PRESCRIPTION_REQUEST';
export const UPDATE_PRESCRIPTION_SUCCESS = 'UPDATE_PRESCRIPTION_SUCCESS';

export const GET_APPOINTMENT_TOKEN = 'GET_APPOINTMENT_TOKEN';
export const GET_APPOINTMENT_TOKEN_SUCCESS = 'GET_APPOINTMENT_TOKEN_SUCCESS';

export const GET_CALENDAR_APPOINTMENTS = 'GET_CALENDAR_APPOINTMENTS';
export const GET_CALENDAR_APPOINTMENTS_SUCCESS = 'GET_CALENDAR_APPOINTMENTS_SUCCESS';

export const GET_CALENDAR_SLOTS = 'GET_CALENDAR_SLOTS';
export const GET_CALENDAR_SLOTS_SUCCESS = 'GET_CALENDAR_SLOTS_SUCCESS';

export const UPDATE_CALENDAR_SLOT = 'UPDATE_CALENDAR_SLOT';
export const UPDATE_CALENDAR_SLOT_SUCCESS = 'UPDATE_CALENDAR_SLOT_SUCCESS';

export const BOOK_VIA_CREDIT = 'BOOK_VIA_CREDIT';
export const BOOK_VIA_CREDIT_SUCCESS = 'BOOK_VIA_CREDIT_SUCCESS';