import React, { Suspense } from "react";
import { Router, Switch } from "react-router-dom";
import { useSelector } from 'react-redux';
import history from "./History";
import * as LazyComponent from "../utils/LazyLoaded";
import Loader from "../components/Loader/Loader";
import PrivateRoute from "../utils/PrivateRoute";

function Routes() {
	const { loggedIn } = useSelector(state => state.auth);
	const loader = useSelector(state => state.loader);

	return (
		<Suspense fallback={<Loader />}>
			<Router history={history}>
				<Switch>
					<LazyComponent.Home path="/" exact />
					<LazyComponent.Login path="/login" exact />
					<LazyComponent.ForgotPassword path="/forgotpassword" exact />
					<LazyComponent.Register path="/signup" exact />
					<LazyComponent.Find path="/find" exact />
					<LazyComponent.Specialties path="/specialties" exact />
					<LazyComponent.Doctor path="/doctor" exact />
					<LazyComponent.Doctors path="/doctors" exact />
					<LazyComponent.AboutUs path="/about" exact />
					<LazyComponent.Membership path="/membership" exact />
					<LazyComponent.WhyUs path="/why-us" exact />
					<LazyComponent.GetListed path="/get-listed" exact />
					<LazyComponent.ContactUs path="/contact-us" exact />

					{loggedIn && (
						<PrivateRoute authenticated={true} component={LazyComponent.Main} path="/" />
					)}
				</Switch>
			</Router>

			{ loader && (
				<Loader />
			)}
		</Suspense>
	);
}

export default Routes;
