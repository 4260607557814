import * as types from '../types/PaymentTypes';

export const processPayment = (params) => ({
    type: types.PROCESS_PAYMENT_REQUEST,
    params
});

export const onProcessPaymentSuccess = (payload) => ({
    type: types.PROCESS_PAYMENT_SUCCESS
});

export const buyAppointmentCredits = (params) => ({
    type: types.BUY_APPOINTMENT_CREDITS,
    params
});

export const onBuyAppointmentCreditsSuccess = (payload) => ({
    type: types.BUY_APPOINTMENT_CREDITS_SUCCESS
});