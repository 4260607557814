import { call, put } from "redux-saga/effects";
import API from "../../network/apis/Appointment";
import { dispatchSnackbarSuccess, dispatchSnackbarError } from "../../utils/Shared";
import * as ACTIONS from "../actions/Appointment";
import * as TYPES from "../types/AppointmentTypes";
import { takeLatest } from "redux-saga/effects";

export function* getAppointments(action) {
    try {
        const response = yield call(API.getAppointments, action.userType, action.userId, action.tab);

        if (response.data.success) {
            yield put(ACTIONS.onGetAppointmentsSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getMoreAppointments(action) {
    try {
        const response = yield call(API.getMoreAppointments, action.url);

        if (response.data.success) {
            yield put(ACTIONS.onGetMoreAppointmentsSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* addAppointmentImage(action) {
    try {
        const response = yield call(API.addAppointmentImage, action.appointmentId, action.userType, action.base64Image);

        if (response.data.success) {
            yield put(ACTIONS.onAddAppointmentImageSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* updateAppointmentImage(action) {
    try {
        const response = yield call(API.updateAppointmentImage, action.appointmentId, action.userType, action.images);

        if (response.data.success) {
            yield put(ACTIONS.onUpdateAppointmentImageSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getAppointment(action) {
    try {
        const response = yield call(API.getAppointment, action.appointmentId);

        if (response.data.success) {
            yield put(ACTIONS.onGetAppointmentSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* updatePrescription(action) {
    try {
        const response = yield call(API.updatePrescription, action.appointmentId, action.params);

        if (response.data.success) {
            yield put(ACTIONS.onUpdatePrescriptionSuccess());
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getAppointmentToken(action) {
    try {
        const response = yield call(API.getAppointmentToken, action.appointmentId);

        if (response.data.success) {
            yield put(ACTIONS.onGetAppointmentTokenSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getCalendarAppointments(action) {
    try {
        const response = yield call(API.getCalendarAppointments, action.userType, action.userId);

        if (response.data.success) {
            yield put(ACTIONS.onGetCalendarAppointmentsSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* getCalendarSlots(action) {
    try {
        const response = yield call(API.getCalendarSlots, action.userId, action.day);

        if (response.data.success) {
            yield put(ACTIONS.onGetCalendarSlotsSuccess(response.data.data));
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* updateCalendarSlots(action) {
    try {
        const response = yield call(API.updateCalendarSlots, action.params);

        if (response.data.success) {
            yield put(ACTIONS.onUpdateCalendarSlotSuccess());
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* bookAppointmentViaCredit(action) {
    try {
        const response = yield call(API.bookAppointmentViaCredit, action.params);

        if (response.data.success) {
            yield put(ACTIONS.onBookAppointmentViaCreditSuccess());
            dispatchSnackbarSuccess(response.data.message);
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* appointmentSagas() {
    yield takeLatest(TYPES.GET_APPOINTMENTS_REQUEST, getAppointments);
    yield takeLatest(TYPES.GET_MORE_APPOINTMENTS_REQUEST, getMoreAppointments);
    yield takeLatest(TYPES.ADD_APPOINTMENT_IMAGE, addAppointmentImage);
    yield takeLatest(TYPES.UPDATE_APPOINTMENT_IMAGE, updateAppointmentImage);
    yield takeLatest(TYPES.GET_APPOINTMENT_REQUEST, getAppointment);
    yield takeLatest(TYPES.UPDATE_PRESCRIPTION_REQUEST, updatePrescription);
    yield takeLatest(TYPES.GET_APPOINTMENT_TOKEN, getAppointmentToken);
    yield takeLatest(TYPES.GET_CALENDAR_APPOINTMENTS, getCalendarAppointments);
    yield takeLatest(TYPES.GET_CALENDAR_SLOTS, getCalendarSlots);
    yield takeLatest(TYPES.UPDATE_CALENDAR_SLOT, updateCalendarSlots);
    yield takeLatest(TYPES.BOOK_VIA_CREDIT, bookAppointmentViaCredit);
}