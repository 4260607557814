import * as types from '../types/AppointmentTypes';

export const getAppointments = (userType, userId, tab) => ({
    type: types.GET_APPOINTMENTS_REQUEST,
    userType,
    userId,
    tab
});

export const onGetAppointmentsSuccess = (payload) => ({
    type: types.GET_APPOINTMENTS_SUCCESS,
    payload
});

export const getMoreAppointments = (url) => ({
    type: types.GET_MORE_APPOINTMENTS_REQUEST,
    url
});

export const onGetMoreAppointmentsSuccess = (payload) => ({
    type: types.GET_MORE_APPOINTMENTS_SUCCESS,
    payload
});

export const addAppointmentImage = (appointmentId, userType, base64Image) => ({
    type: types.ADD_APPOINTMENT_IMAGE,
    appointmentId,
    userType,
    base64Image
});

export const onAddAppointmentImageSuccess = (payload) => ({
    type: types.ADD_APPOINTMENT_IMAGE_SUCCESS,
    payload
});

export const updateAppointmentImage = (appointmentId, userType, images) => ({
    type: types.UPDATE_APPOINTMENT_IMAGE,
    appointmentId,
    userType,
    images
});

export const onUpdateAppointmentImageSuccess = (payload) => ({
    type: types.UPDATE_APPOINTMENT_IMAGE_SUCCESS,
    payload
});

export const getAppointment = (appointmentId) => ({
    type: types.GET_APPOINTMENT_REQUEST,
    appointmentId
});

export const onGetAppointmentSuccess = (payload) => ({
    type: types.GET_APPOINTMENT_SUCCESS,
    payload
});

export const updatePrescription = (appointmentId, params) => ({
    type: types.UPDATE_PRESCRIPTION_REQUEST,
    appointmentId,
    params
});

export const onUpdatePrescriptionSuccess = (payload) => ({
    type: types.UPDATE_PRESCRIPTION_SUCCESS,
    payload
});

export const getAppointmentToken = (appointmentId) => ({
    type: types.GET_APPOINTMENT_TOKEN,
    appointmentId
});

export const onGetAppointmentTokenSuccess = (payload) => ({
    type: types.GET_APPOINTMENT_TOKEN_SUCCESS,
    payload
});

export const getCalendarAppointments = (userType, userId) => ({
    type: types.GET_CALENDAR_APPOINTMENTS,
    userType,
    userId
});

export const onGetCalendarAppointmentsSuccess = (payload) => ({
    type: types.GET_CALENDAR_APPOINTMENTS_SUCCESS,
    payload
});

export const getCalendarSlots = (userId, day) => ({
    type: types.GET_CALENDAR_SLOTS,
    userId,
    day
});

export const onGetCalendarSlotsSuccess = (payload) => ({
    type: types.GET_CALENDAR_SLOTS_SUCCESS,
    payload
});

export const updateCalendarSlot = (params) => ({
    type: types.UPDATE_CALENDAR_SLOT,
    params
});

export const onUpdateCalendarSlotSuccess = (payload) => ({
    type: types.UPDATE_CALENDAR_SLOT_SUCCESS,
    payload
});

export const bookAppointmentViaCredit = (params) => ({
    type: types.BOOK_VIA_CREDIT,
    params
});

export const onBookAppointmentViaCreditSuccess = (payload) => ({
    type: types.BOOK_VIA_CREDIT_SUCCESS,
    payload
});