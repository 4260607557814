export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';

export const REQUEST_OTP = 'REQUEST_OTP';
export const REQUEST_OTP_SUCCESS = 'REQUEST_OTP_SUCCESS';

export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';

export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';

export const GET_MEDICAL_HISTORY = 'GET_MEDICAL_HISTORY';
export const GET_MEDICAL_HISTORY_SUCCESS = 'GET_MEDICAL_HISTORY_SUCCESS';

export const UPDATE_MEDICAL_HISTORY = 'UPDATE_MEDICAL_HISTORY';
export const UPDATE_MEDICAL_HISTORY_SUCCESS = 'UPDATE_MEDICAL_HISTORY_SUCCESS';