import * as types from '../types/SpecialtyTypes';

export const enableGetSpecialtiesLoader = () => ({
    type: types.ENABLE_GET_SPECIALTIES_LOADER
});

export const disableGetSpecialtiesLoader = () => ({
    type: types.DISABLE_GET_SPECIALTIES_LOADER
});

export const getSpecialties = () => ({
    type: types.GET_SPECIALTIES_REQUEST
});

export const onGetSpecialtiesSuccess = (payload) => ({
    type: types.GET_SPECIALTIES_SUCCESS,
    payload
});