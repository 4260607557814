import Auth from '../../utils/Auth';
import { axiosInstance } from "./index";
const handlerEnabled = false;

const getSpecialties = async () => {
    return await axiosInstance.get('api/specialties', {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
}

export default {
    getSpecialties
};