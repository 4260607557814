import Auth from '../../utils/Auth';
import { combineReducers } from "redux";
import lang from "./Lang";
import loader from "./Loader";
import snackbar from "./Snackbar";
import auth from './Auth';
import specialty from './Specialty';
import doctor from './Doctor';
import payment from './Payment';
import appointment from './Appointment';
import account from './Account';

import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers({
  lang,
  loader,
  snackbar,
  auth,
  specialty,
  doctor,
  payment,
  appointment,
  account
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'AUTH_LOGOUT_REQUEST') {
    Auth.signOut();
    storage.removeItem('persist:root');
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;