import { call, put } from "redux-saga/effects";
import API from "../../network/apis/Payment";
import { dispatchSnackbarError, dispatchSnackbarSuccess } from "../../utils/Shared";
import { takeLatest } from "redux-saga/effects";
import * as ACTIONS from "../actions/Payment";
import * as TYPES from "../types/PaymentTypes";
import History from '../../routes/History';

export function* processPayment(action) {
    try {
        const response = yield call(API.processPayment, action.params);

        if (response.data.success) {
            yield put(ACTIONS.onProcessPaymentSuccess());
            dispatchSnackbarSuccess(response.data.message);
            History.push('/appointments');
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* buyAppointmentCredits(action) {
    try {
        const response = yield call(API.buyAppointmentCredits, action.params);

        if (response.data.success) {
            yield put(ACTIONS.onBuyAppointmentCreditsSuccess());
            dispatchSnackbarSuccess(response.data.message);
        } else {
            dispatchSnackbarError(response.data.message);
        }
    } catch (err) {
        dispatchSnackbarError(err.response.data.message);
    }
}

export function* paymentSagas() {
    yield takeLatest(TYPES.PROCESS_PAYMENT_REQUEST, processPayment);
    yield takeLatest(TYPES.BUY_APPOINTMENT_CREDITS, buyAppointmentCredits);
}
