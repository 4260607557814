import * as types from "../types/AppointmentTypes";

const INITIAL_STATE = {
    appointmentsList: [],
    nextPageUrl: null,
    isAddAppointmentImageSuccess: false,
    isUpdateAppointmentImageSuccess: false,
    updatedAppointment: null,
    isUpdatePrescriptionSuccess: false,
    appointmentToken: null,
    calendarAppointmentsList: [],
    calendarSlotsList: [],
    isUpdateCalendarSlotSuccess: false,
    isBookAppointmentViaCreditSuccess: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_APPOINTMENTS_REQUEST:
            return {
                ...state,
                userType: action.userType,
                userId: action.userId,
                tab: action.tab
            };
        case types.GET_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                appointmentsList: action.payload.data,
                nextPageUrl: action.payload.next_page_url
            }
        case types.GET_MORE_APPOINTMENTS_REQUEST:
            return {
                ...state,
                url: action.url
            };
        case types.GET_MORE_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                appointmentsList: [...state.appointmentsList, ...action.payload.data],
                nextPageUrl: action.payload.next_page_url
            }
        case types.ADD_APPOINTMENT_IMAGE:
            return {
                ...state,
                isAddAppointmentImageSuccess: false,
                appointmentId: action.appointmentId,
                userType: action.userType,
                base64Image: action.base64Image
            };
        case types.ADD_APPOINTMENT_IMAGE_SUCCESS:
            return {
                ...state,
                isAddAppointmentImageSuccess: true
            }
        case types.UPDATE_APPOINTMENT_IMAGE:
            return {
                ...state,
                isUpdateAppointmentImageSuccess: false,
                appointmentId: action.appointmentId,
                userType: action.userType,
                images: action.images
            };
        case types.UPDATE_APPOINTMENT_IMAGE_SUCCESS:
            return {
                ...state,
                isUpdateAppointmentImageSuccess: true
            }
        case types.GET_APPOINTMENT_REQUEST:
            return {
                ...state,
                appointmentId: action.appointmentId
            };
        case types.GET_APPOINTMENT_SUCCESS:
            return {
                ...state,
                updatedAppointment: action.payload
            }
        case types.UPDATE_PRESCRIPTION_REQUEST:
            return {
                ...state,
                isUpdatePrescriptionSuccess: false,
                appointmentId: action.appointmentId,
                params: action.params
            };
        case types.UPDATE_PRESCRIPTION_SUCCESS:
            return {
                ...state,
                isUpdatePrescriptionSuccess: true
            }
        case types.GET_APPOINTMENT_TOKEN:
            return {
                ...state,
                appointmentId: action.appointmentId
            };
        case types.GET_APPOINTMENT_TOKEN_SUCCESS:
            return {
                ...state,
                appointmentToken: action.payload
            }
        case types.GET_CALENDAR_APPOINTMENTS:
            return {
                ...state,
                userType: action.userType,
                userId: action.userId
            };
        case types.GET_CALENDAR_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                calendarAppointmentsList: action.payload
            }
        case types.GET_CALENDAR_SLOTS:
            return {
                ...state,
                userId: action.userId,
                day: action.day
            };
        case types.GET_CALENDAR_SLOTS_SUCCESS:
            return {
                ...state,
                calendarSlotsList: action.payload
            }
        case types.BOOK_VIA_CREDIT:
            return {
                ...state,
                params: action.params,
                isBookAppointmentViaCreditSuccess: false
            };
        case types.BOOK_VIA_CREDIT_SUCCESS:
            return {
                ...state,
                isBookAppointmentViaCreditSuccess: true
            }
        default:
            return state;
    }
};