import * as types from "../types/AuthTypes";

const INITIAL_STATE = {
    loggedIn: false,
    userInfo: null,
    subsInfo: null,
    userAccountsList: [],
    newUserInfo: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.AUTH_REGISTER_REQUEST:
            return {
                ...state,
                params: action.params
            };
        case types.AUTH_REGISTER_SUCCESS:
            return {
                ...state,
                newUserInfo: action.payload
            }
        case types.AUTH_LOGIN_REQUEST:
            return {
                ...state,
                email: action.email,
                password: action.password,
                userType: action.userType
            }
        case types.AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                userInfo: action.userInfo
            }
        case types.AUTH_LOGOUT_REQUEST:
            return {
                ...state,
                loggedIn: false
            }
        case types.AUTH_USER_INFO_REQUEST:
            return {
                ...state
            }
        case types.AUTH_USER_INFO_SUCCESS:
            return {
                ...state,
                userInfo: action.payload
            }
        case types.AUTH_SUBS_INFO_REQUEST:
            return {
                ...state,
                phone: action.phone
            }
        case types.AUTH_SUBS_INFO_SUCCESS:
            return {
                ...state,
                subsInfo: action.payload
            }
        case types.AUTH_USER_ACCOUNTS_REQUEST:
            return {
                ...state
            }
        case types.AUTH_USER_ACCOUNTS_SUCCESS:
            return {
                ...state,
                userAccountsList: action.payload
            }
        case types.AUTH_RESET_PASSWORD_REQUEST:
            return {
                ...state,
                params: action.params
            }
        default:
            return state;
    }
};