import * as types from "../types/AccountTypes";

const INITIAL_STATE = {
    isUpdateAccountSuccess: false,
    isUpdatePasswordSuccess: false,
    isRequestOtpSuccess: false,
    isVerifyOtpSuccess: false,
    transactions: [],
    medicalHistory: null,
    isUpdateMedicalHistorySuccess: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.UPDATE_ACCOUNT:
            return {
                ...state,
                userId: action.userId,
                params: action.params,
                currentUserInfo: action.currentUserInfo,
                isUpdateAccountSuccess: false
            };
        case types.UPDATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                isUpdateAccountSuccess: true
            };
        case types.UPDATE_PASSWORD:
            return {
                ...state,
                params: action.params,
                isUpdatePasswordSuccess: false
            };
        case types.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                isUpdatePasswordSuccess: true
            };
        case types.REQUEST_OTP:
            return {
                ...state,
                userId: action.userId,
                params: action.params,
                isRequestOtpSuccess: false
            };
        case types.REQUEST_OTP_SUCCESS:
            return {
                ...state,
                isRequestOtpSuccess: true
            };
        case types.VERIFY_OTP:
            return {
                ...state,
                userId: action.userId,
                params: action.params,
                isVerifyOtpSuccess: false
            };
        case types.VERIFY_OTP_SUCCESS:
            return {
                ...state,
                isVerifyOtpSuccess: true
            };
        case types.GET_TRANSACTIONS:
            return {
                ...state,
                userId: action.userId,
                params: action.params
            };
        case types.GET_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                transactions: action.payload
            };
        case types.GET_MEDICAL_HISTORY:
            return {
                ...state,
                userId: action.userId
            };
        case types.GET_MEDICAL_HISTORY_SUCCESS:
            return {
                ...state,
                medicalHistory: action.payload
            };
        case types.UPDATE_MEDICAL_HISTORY:
            return {
                ...state,
                userId: action.userId,
                params: action.params,
                isUpdateMedicalHistorySuccess: false
            };
        case types.UPDATE_MEDICAL_HISTORY_SUCCESS:
            return {
                ...state,
                isUpdateMedicalHistorySuccess: true
            };
        default:
            return state;
    }
};