import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import ThemeApp from "./Theme";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react'

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeApp />
    </PersistGate>
  </Provider>,
  document.querySelector('#root')
);
