import { authSagas } from './AuthSagas';
import { specialtySagas } from './SpecialtySagas';
import { doctorSagas } from './DoctorSagas';
import { paymentSagas } from './PaymentSagas';
import { appointmentSagas } from './AppointmentSagas';
import { accountSagas } from './AccountSagas';
import { all } from "redux-saga/effects";

export function* watchSagas() {
	yield all([
		authSagas(),
		specialtySagas(),
		doctorSagas(),
		paymentSagas(),
		appointmentSagas(),
		accountSagas()
	]);
}
