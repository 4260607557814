import * as types from "../types/PaymentTypes";

const INITIAL_STATE = {
    isPaymentSuccess: false,
    isBuyAppointmentCreditsSuccess: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.PROCESS_PAYMENT_REQUEST:
            return {
                ...state,
                isPaymentSuccess: false,
                params: action.params
            };
        case types.PROCESS_PAYMENT_SUCCESS:
            return {
                ...state,
                isPaymentSuccess: true
            };
        case types.BUY_APPOINTMENT_CREDITS:
            return {
                ...state,
                isBuyAppointmentCreditsSuccess: false,
                params: action.params
            };
        case types.BUY_APPOINTMENT_CREDITS_SUCCESS:
            return {
                ...state,
                isBuyAppointmentCreditsSuccess: true
            };
        default:
            return state;
    }
};