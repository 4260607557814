import Auth from '../../utils/Auth';
import { axiosInstance } from "./index";
const handlerEnabled = true;

const processPayment = async (params) => {
    return await axiosInstance.post('/api/payment/web', params, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
};

const buyAppointmentCredits = async (params) => {
    return await axiosInstance.post('/api/capturecreditspayment', params, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
};

export default {
    processPayment,
    buyAppointmentCredits
};