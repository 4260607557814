import Auth from '../../utils/Auth';
import { axiosInstance } from "./index";
const handlerEnabled = true;

const getFeaturedDoctors = async () => {
    return await axiosInstance.get('api/doctors/featured', {
        handlerEnabled
    });
}

const getDoctorSchedule = async (doctorUserId, date) => {
    return await axiosInstance.get(`api/doctors/schedule/${doctorUserId}?date=${date}`, {
        handlerEnabled
    });
}

const getAllSpecialties = async () => {
    return await axiosInstance.get(`api/specialties/input`, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
}

const getDoctorsInfo = async (userId) => {
    return await axiosInstance.get(`api/doctors/${userId}`, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
}

const updateDoctorsInfo = async (userId, params) => {
    return await axiosInstance.put(`api/doctors/${userId}`,
        params,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

const getSchedule = async (userId) => {
    return await axiosInstance.get(`api/doctors/${userId}`, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
}

const updateSchedule = async (userId, params) => {
    return await axiosInstance.put(`api/doctors/${userId}/edit_schedule`,
        params,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

const getDoctorsBySpecialty = async (specialtyId) => {
    return await axiosInstance.get(`api/doctors/specialty/${specialtyId}`, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
}

const getMoreDoctorsBySpecialty = async (url) => {
    return await axiosInstance.get(url, {
        handlerEnabled,
        headers: {
            'Authorization': 'Bearer ' + Auth.isAuth()
        }
    });
}

const getDoctorEarnings = async (userId, params) => {
    return await axiosInstance.get(`api/users/${userId}/payments_date?&start=${params.startDate}&end=${params.endDate}`,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

const getDoctorCredits = async (doctorUserId) => {
    return await axiosInstance.get(`api/doctors/${doctorUserId}/check_credit`,
        {
            handlerEnabled,
            headers: {
                'Authorization': 'Bearer ' + Auth.isAuth()
            }
        });
};

export default {
    getFeaturedDoctors,
    getDoctorSchedule,
    getAllSpecialties,
    getDoctorsInfo,
    updateDoctorsInfo,
    getSchedule,
    updateSchedule,
    getDoctorsBySpecialty,
    getMoreDoctorsBySpecialty,
    getDoctorEarnings,
    getDoctorCredits
};